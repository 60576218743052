import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { Layout } from "../components/Layout";
import Seo from "../components/SEO";
import { Heading3 } from "../components/Typography";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";

const StyledResults = styled.article`
  max-width: 1000px;
`;

const StyledTitle = styled(Heading3)`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const ResultsTable = styled(Table)`
  min-width: 1000px;
`;

const DistinctCell = styled(TableCell)`
  background-color: #2929292b;
`;

const HeaderCell = styled(DistinctCell)`
  font-weight: bold;
  background-color: #ff4d00c7;
  text-transform: uppercase;
`;

const ResultsTemplate = ({ data, pageContext: { seasonId } }) => {
  const title = `${seasonId}. JFS sezonas skolu kausa rezultāti`;

  const lessonIndex = data.allLesson.nodes
    .reduce((acc, lesson) => ({...acc, [lesson.lessonId]: lesson}), {})

  const teams = data.allSchoolCupTeam.nodes
    .map((team) => {
      const { results } = team;
      const testResultsSum = results
        .filter(({ type }) => type === "testScores")
        .filter(({ key }) => !!lessonIndex[key].includedInSC)
        .reduce((acc, { score }) => acc + score, 0);
      const extraResultsSum = results
        .filter(({ type }) => type === "extraScores")
        .reduce((acc, { score }) => acc + score, 0);
      const totalSum = testResultsSum + extraResultsSum;
      const resultsMap = results.reduce(
        (acc, { key, score }) => ({ ...acc, [key]: score }),
        {}
      );
      return { ...team, testResultsSum, extraResultsSum, totalSum, resultsMap };
    })
    .sort(({ totalSum: totalSumA }, { totalSum: totalSumB }) =>
      totalSumA > totalSumB ? -1 : 0
    );

  const testScoreHeaders = [
    ...teams.reduce((acc, { results }) => {
      const headers = results
        .filter(({ type }) => type === "testScores")
        .filter(({ key }) => !!lessonIndex[key].includedInSC)
        .map(({ key }) => key);
      headers.forEach((header) => acc.add(header));
      return acc;
    }, new Set()),
  ].sort((a, b) => (a < b ? -1 : 0));

  const extraScoreHeaders = [
    ...teams.reduce((acc, { results }) => {
      const headers = results
        .filter(({ type }) => type === "extraScores")
        .map(({ key }) => key);
      headers.forEach((header) => acc.add(header));
      return acc;
    }, new Set()),
  ].sort((a, b) => (a < b ? -1 : 0));

  return (
    <Layout>
      <Seo title={title} description={title} />
      <StyledResults>
        <StyledTitle>{title}</StyledTitle>
        <TableWrapper>
          <ResultsTable>
            <TableHead>
              <TableRow>
                <HeaderCell>Nosaukums</HeaderCell>
                <HeaderCell>Skola</HeaderCell>
                {testScoreHeaders.map((header) => (
                  <HeaderCell align="center" key={header}>
                    {header}
                  </HeaderCell>
                ))}
                <HeaderCell align="center">T kopā</HeaderCell>
                {extraScoreHeaders.map((header) => (
                  <HeaderCell align="center" key={header}>
                    {header}
                  </HeaderCell>
                ))}
                <HeaderCell align="center">MD kopā</HeaderCell>
                <HeaderCell align="center">Kopā</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map(
                ({
                  id,
                  name,
                  school,
                  resultsMap,
                  testResultsSum,
                  extraResultsSum,
                  totalSum,
                }) => (
                  <TableRow key={id}>
                    <DistinctCell>{name}</DistinctCell>
                    <DistinctCell>{school}</DistinctCell>
                    {testScoreHeaders.map((header) => (
                      <TableCell align="center" key={header}>
                        {resultsMap[header]}
                      </TableCell>
                    ))}
                    <DistinctCell align="center">{testResultsSum}</DistinctCell>
                    {extraScoreHeaders.map((header) => (
                      <TableCell align="center" key={header}>
                        {resultsMap[header] || 0}
                      </TableCell>
                    ))}
                    <DistinctCell align="center">
                      {extraResultsSum}
                    </DistinctCell>
                    <HeaderCell align="center">{totalSum}</HeaderCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </ResultsTable>
        </TableWrapper>
      </StyledResults>
    </Layout>
  );
};

export default ResultsTemplate;

export const pageQuery = graphql`
  query ResultsBySeason($seasonId: String!) {
    allLesson(filter: {seasonId: {eq: $seasonId}}) {
      nodes {
        lessonId
        seasonId
        includedInSC
      }
    }
    allSchoolCupTeam(filter: { season: { eq: $seasonId } }) {
      nodes {
        id
        season
        school
        results {
          key
          score
          type
        }
        name
      }
    }
  }
`;
